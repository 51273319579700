<template>
  <div v-loading="loading" class="form-container">
    <div v-if="getErrorStatus" class="error-message">
      <span> An error occurred while submitting the form, please enter all valid fields and try again. </span>
    </div>
    <a-form ref="form" novalidate>
      <div class="m-bottom-15">
        <FormField
          field-id="firstName"
          placeholder="First Name"
          :validator="$v.firstName"
          :default-value="form.firstName"
          :handle-input="
            (value) => {
              firstName = value;
              $v.firstName.$touch();
            }
          "
        />
      </div>
      <div class="m-bottom-15">
        <FormField
          field-id="lastName"
          placeholder="Last Name"
          :validator="$v.lastName"
          :default-value="form.lastName"
          :handle-input="
            (value) => {
              lastName = value;
              $v.lastName.$touch();
            }
          "
        />
      </div>
      <div class="m-bottom-15">
        <FormField
          field-id="email"
          placeholder="Email Address"
          :validator="$v.email"
          :default-value="form.email"
          :handle-input="
            (value) => {
              email = value;
              $v.email.$touch();
            }
          "
        />
      </div>
      <div class="m-bottom-15">
        <VerticalNormalForm v-if="userAction === 'edit'" form-label="">
          <template slot="form-field">
            <a-input
              v-model="form.phone"
              class="phone-number"
              placeholder="Insert phone"
              @keypress="validateInput($event)"
            />
          </template>
        </VerticalNormalForm>
      </div>
      <span class="info-message">
        Simpatra will send an email to the email provided, allowing for the rest of the account setup process
      </span>
      <BaseButton
        class="form-user-submit-btn"
        type="primary"
        :is-disabled="$v.$invalid || loading"
        :button-text="submitText"
        :handle-click="() => submitForm()"
      />
    </a-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";

import ValidationHelper from "@/utils/validation-helper";

import BaseButton from "@/components/common/AntDesign/BaseButton.vue";
import FormField from "@/components/shared-components/FormField.vue";
import VerticalNormalForm from "@/components/shared-components/VerticalNormalForm/VerticalNormalForm.vue";

export default {
  name: "FormUserInfo",
  components: {
    BaseButton,
    FormField,
    VerticalNormalForm,
  },
  props: {
    user: {
      type: Object,
    },
    submitText: {
      type: String,
      required: true,
    },
    userAction: {
      type: String,
      required: true,
      default: "create",
      validator(value) {
        const options = ["create", "edit"];
        return options.indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
    };
  },
  validations: {
    firstName: {
      "Please enter first name": required,
    },
    lastName: {
      "Please enter last name": required,
    },
    email: { "Email is required": required, "Invalid email address format": email },
  },
  computed: {
    ...mapGetters(["getOrgId", "getErrorStatus"]),
    firstName: {
      get() {
        return this.form.firstName;
      },
      set(newValue) {
        this.handleChange({ propName: "firstName", value: newValue });
      },
    },
    lastName: {
      get() {
        return this.form.lastName;
      },
      set(newValue) {
        this.handleChange({ propName: "lastName", value: newValue });
      },
    },
    email: {
      get() {
        return this.form.email;
      },
      set(newValue) {
        this.handleChange({ propName: "email", value: newValue });
      },
    },
  },
  mounted() {
    if (this.user) {
      this.form.firstName = this.user.firstName;
      this.form.lastName = this.user.lastName;
      this.form.email = this.user.email;
      this.form.phone = this.user.phone;
      if (this.user.phone !== null && this.user.phone !== "") {
        this.form.phone = Number(this.user.phone);
      }
    }
  },
  methods: {
    ...mapActions("accountsModule", ["updateOrgUserInfo", "addOrgUser"]),
    async submitForm() {
      this.loading = true;
      if (this.userAction === "create") {
        const payload = {
          orgId: this.getOrgId,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
        };
        let user;
        if (this.user) {
          payload.userId = this.user.id;
          user = await this.updateOrgUserInfo(payload);
        } else {
          user = await this.addOrgUser(payload);
        }
        if (user) {
          this.$emit("handleStep", {
            step: 1,
            payload: user,
          });
        } else {
          this.loading = false;
        }
      } else {
        const payload = {
          orgId: this.getOrgId,
          userId: this.user.id,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          phone: this.form.phone,
          email: this.form.email.toLowerCase(),
        };
        const response = await this.updateOrgUserInfo(payload);
        setTimeout(() => {
          if (response) {
            this.$emit("updateUserData", payload);
            return this.$emit("toggleForm");
          }
        }, 500);
      }
    },
    handleChange({ propName, value }) {
      this.form[propName] = value;
    },
    validateInput(evt) {
      let validatorUtil = new ValidationHelper();
      if (!validatorUtil.isNumberInput(evt)) {
        evt.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .form-error,
.is-visible {
  color: #f56c6c;
  font-size: smaller;
}
.email {
  border-color: #f56c6c;
}
.form-user-submit-btn {
  width: 100%;
}
.error-message {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  top: 100%;
  left: 0;
  margin-bottom: 10px;
}
.info-message {
  display: block;
  margin-bottom: 15px;
}
</style>
