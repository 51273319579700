var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"form-container"},[(_vm.getErrorStatus)?_c('div',{staticClass:"error-message"},[_c('span',[_vm._v(" An error occurred while submitting the form, please enter all valid fields and try again. ")])]):_vm._e(),_c('a-form',{ref:"form",attrs:{"novalidate":""}},[_c('div',{staticClass:"m-bottom-15"},[_c('FormField',{attrs:{"field-id":"firstName","placeholder":"First Name","validator":_vm.$v.firstName,"default-value":_vm.form.firstName,"handle-input":function (value) {
            _vm.firstName = value;
            _vm.$v.firstName.$touch();
          }}})],1),_c('div',{staticClass:"m-bottom-15"},[_c('FormField',{attrs:{"field-id":"lastName","placeholder":"Last Name","validator":_vm.$v.lastName,"default-value":_vm.form.lastName,"handle-input":function (value) {
            _vm.lastName = value;
            _vm.$v.lastName.$touch();
          }}})],1),_c('div',{staticClass:"m-bottom-15"},[_c('FormField',{attrs:{"field-id":"email","placeholder":"Email Address","validator":_vm.$v.email,"default-value":_vm.form.email,"handle-input":function (value) {
            _vm.email = value;
            _vm.$v.email.$touch();
          }}})],1),_c('div',{staticClass:"m-bottom-15"},[(_vm.userAction === 'edit')?_c('VerticalNormalForm',{attrs:{"form-label":""}},[_c('template',{slot:"form-field"},[_c('a-input',{staticClass:"phone-number",attrs:{"placeholder":"Insert phone"},on:{"keypress":function($event){return _vm.validateInput($event)}},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)],2):_vm._e()],1),_c('span',{staticClass:"info-message"},[_vm._v(" Simpatra will send an email to the email provided, allowing for the rest of the account setup process ")]),_c('BaseButton',{staticClass:"form-user-submit-btn",attrs:{"type":"primary","is-disabled":_vm.$v.$invalid || _vm.loading,"button-text":_vm.submitText,"handle-click":function () { return _vm.submitForm(); }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }