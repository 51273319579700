<template>
  <div v-loading="loading" class="form-container">
    <el-form ref="form">
      <p class="info-label">Administrator</p>
      <el-tooltip
        v-if="displayOrgAdminRole"
        content="Allows user to manage the organization's users and locations"
        placement="top"
      >
        <el-checkbox
          v-model="isOrgAdmin"
          class="permission"
          border
          size="small"
          :disabled="isOrgAdminRoleDisabled"
          @change="checkboxOnUpdate('isOrgAdmin')"
          label="Organization Admin"
        />
      </el-tooltip>
      <el-tooltip content="Allows user to manage the locations" placement="top">
        <el-checkbox
          v-model="isLocationAdmin"
          class="permission"
          border
          size="small"
          :disabled="isOrgAdmin"
          label="Location Admin"
        />
      </el-tooltip>
      <el-tooltip content="Allows user to manage the patient orders" placement="top">
        <el-checkbox
          v-if="isSimpatraAdmin"
          v-model="isApprover"
          class="permission secondary-admin"
          border
          size="small"
          label="Approver"
        />
      </el-tooltip>
      <p class="info-label">Location</p>
      <div class="location-permissions">
        <el-tooltip content="Allows user to view past invoices" placement="top">
          <el-checkbox
            v-model="permissions.locInvoices.value"
            class="permission"
            :disabled="isOrgAdmin"
            border
            size="small"
            label="Invoices"
          />
        </el-tooltip>
        <el-tooltip content="Allows user to manage DEA licenses and Vendor registration" placement="top">
          <el-checkbox
            v-model="permissions.locLicensesAndRegistrations.value"
            class="permission"
            :disabled="isOrgAdmin"
            border
            size="small"
            label="Licenses and Registrations"
          />
        </el-tooltip>
        <el-tooltip content="Allows user place orders and manage payment methods" placement="top">
          <el-checkbox
            v-model="permissions.locOrdersAndPayment.value"
            class="permission"
            :disabled="isOrgAdmin"
            border
            size="small"
            label="Orders and Payments"
          />
        </el-tooltip>
        <el-tooltip content="Allows user access patient data and schedule appointments" placement="top">
          <el-checkbox
            v-model="permissions.locPatientAndScheduling.value"
            class="permission"
            :disabled="isOrgAdmin"
            border
            size="small"
            label="Patients and Appointments"
          />
        </el-tooltip>
        <el-tooltip content="Allows user update location info (such as ship-to address)" placement="top">
          <el-checkbox
            v-model="permissions.locProfile.value"
            class="permission"
            :disabled="isOrgAdmin"
            border
            size="small"
            label="Location Info"
          />
        </el-tooltip>
      </div>

      <div v-if="isSimpatraAdmin && userAction === 'edit'">
        <p class="info-label directory-txt">Directory</p>
        <el-tooltip content="Include user in the directory" placement="top">
          <el-checkbox
            v-model="isIncludedDirectory"
            class="permission"
            :class="{ directory: isIncludedDirectory }"
            border
            size="small"
            :disabled="!isDosingUser"
            label="Include In Directory, available for dosing user"
          />
        </el-tooltip>
      </div>

      <BaseButton class="save-button" type="form" @click="handleFormSubmit">
        {{ submitText }}
      </BaseButton>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { forOwn, isEmpty } from "lodash";
import BaseButton from "@/components/common/BaseButton.vue";
import userMe from "@/services/user-me";

export default {
  name: "FormUserPermissions",
  components: {
    BaseButton,
  },
  props: {
    submitText: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
    },
    userAction: {
      type: String,
      required: true,
      default: "create",
      validator(value) {
        const options = ["create", "edit"];
        return options.indexOf(value) !== -1;
      },
    },
    isDosingUser: Boolean
  },
  data() {
    return {
      loading: false,
      isOrgAdmin: false,
      permissions: {
        locProfile: {
          name: "LocProfile",
          value: false,
        },
        locLicensesAndRegistrations: {
          name: "LocLicensesAndRegistrations",
          value: false,
        },
        locOrdersAndPayment: {
          name: "LocOrdersAndPayment",
          value: false,
        },
        locInvoices: {
          name: "LocInvoices",
          value: false,
        },
        locPatientAndScheduling: {
          name: "LocPatientAndScheduling",
          value: false,
        },
        locAdmin: {
          name: "LocAdmin",
          value: false,
        },
      },
      superAdminPermissions: {
        supVendorManagement: false,
        supOrganizationManagement: false,
        supSubscriptionManagement: false,
        supDirectoryManagement: false,
      },
      isIncludedDirectory: false,
      isApprover: false,
      isLocationAdmin: false,
    };
  },
  computed: {
    ...mapGetters([
      "getOrgId",
      "getCurrentUser",
    ]),
    currentUserLoggedIn() {
      return this.user && this.getCurrentUser.email === this.user.email;
    },
    displayOrgAdminRole() {
      const { isSimpatraAdmin, isOrgAdmin, isOrgOwner } = this.getMyRoles;
      if (this.userAction === "create") {
        return isOrgOwner || isSimpatraAdmin || isOrgAdmin;
      }
      return true;
    },
    getMyRoles() {
      return userMe.getMyRoles();
    },
    isSimpatraAdmin() {
      return this.getMyRoles.isSimpatraAdmin;
    },
    isOrgAdminRoleDisabled() {
      const { isSimpatraAdmin, isOrgAdmin, isOrgOwner } = this.getMyRoles;
      return !isSimpatraAdmin || !isOrgAdmin || !isOrgOwner;
    },
  
  },
  mounted() {
    if (this.user) {
      // ADMIN PROPERTY USED FOR ORG ADMIN ROLE
      this.isOrgAdmin = this.user && this.user.admin;

      if (!isEmpty(this.user.locationPermissions)) {
        this.user.locationPermissions.map((permission) => {
          if ((permission.name === "LocLicenses" || permission.name === "LocRegistration") && permission.value > 0) {
            this.permissions.locLicensesAndRegistrations.value = true;
          } else if ((permission.name === "LocOrders" || permission.name === "LocPayment") && permission.value > 0) {
            this.permissions.locOrdersAndPayment.value = true;
          } else if (
            (permission.name === "LocPatient" || permission.name === "LocScheduling") &&
            permission.value > 0
          ) {
            this.permissions.locPatientAndScheduling.value = true;
          } else {
            const permissionName = permission.name.charAt(0).toLowerCase() + permission.name.slice(1);
            if (this.permissions[permissionName]) {
              this.permissions[permissionName].value = permission.value > 0;
            }
          }
        });
        this.isLocationAdmin = this.permissions.locAdmin.value;
      }

      // ORG PERMISSIONS
      if (!isEmpty(this.user.organizationPermissions)) {
        const OrgDirectory = this.user.organizationPermissions.find((permission) => {
          return permission.name === "OrgDirectory";
        });
        this.isIncludedDirectory = OrgDirectory.value !== 0;

        const OrgApprover = this.user.organizationPermissions.find((permission) => {
          return permission.name === "Approver";
        });

        this.isApprover = OrgApprover.value !== 0;
      }

      // SUPER ADMIN PERMISSIONS
      if (!isEmpty(this.user.superAdminPermissions)) {
        const supAdminPerms = this.user.superAdminPermissions;
        this.superAdminPermissions.supVendorManagement =
          supAdminPerms.find((permission) => {
            return permission.name === "supVendorManagement";
          }).value > 0;

        this.superAdminPermissions.supOrganizationManagement =
          supAdminPerms.find((permission) => {
            return permission.name === "supOrganizationManagement";
          }).value > 0;

        this.superAdminPermissions.supSubscriptionManagement =
          supAdminPerms.find((permission) => {
            return permission.name === "supSubscriptionManagement";
          }).value > 0;

        this.superAdminPermissions.supDirectoryManagement =
          supAdminPerms.find((permission) => {
            return permission.name === "supDirectoryManagement";
          }).value > 0;
      }
    }
  },
  methods: {
    ...mapMutations("accountsModule", ["updateUserWithNew"]),
    ...mapActions("accountsModule", [
      "updateUserWithNew",
      "removeOrgAdminPermission",
      "grantOrgAdminPermission",
      "updateLocationPermissions",
      "updateOrgPermissions",
      "updateSuperAdminPermissions",
    ]),
    joinPermissions(arrayToJoin, permissionNames, value) {
      permissionNames.forEach((name) => {
        arrayToJoin.push({
          name,
          value,
        });
      });
      return arrayToJoin;
    },
    checkboxOnUpdate(type) {
      if(type === 'isOrgAdmin' && this[type]) {
        this.permissions.locProfile.value = true;
        this.permissions.locPatientAndScheduling.value = true;
        this.permissions.locOrdersAndPayment.value = true;
        this.permissions.locLicensesAndRegistrations.value = true;
        this.permissions.locInvoices.value = true;
      }
    },
    async updateLocationRole() {
      this.permissions.locAdmin.value = this.isLocationAdmin;

      const locationPermissions = [];

      await forOwn(this.permissions, (permission) => {
        const valNum = permission.value ? 2 : 0;
        if (permission.name === "LocPatientAndScheduling") {
          ["LocPatient", "LocScheduling"].forEach((name) => {
            locationPermissions.push({
              name,
              value: valNum,
            });
          });
        } else if (permission.name === "LocLicensesAndRegistrations") {
          ["LocLicenses", "LocRegistration"].forEach((name) => {
            locationPermissions.push({
              name,
              value: valNum,
            });
          });
        } else if (permission.name === "LocOrdersAndPayment") {
          ["LocOrders", "LocPayment"].forEach((name) => {
            locationPermissions.push({
              name,
              value: valNum,
            });
          });
        } else {
          locationPermissions.push({
            name: permission.name.charAt(0).toUpperCase() + permission.name.slice(1),
            value: valNum,
          });
        }
      });

      const locationPayload = {
        orgId: this.getOrgId,
        userId: this.user.id,
        locationPermissions,
      };

      const locationResult = await this.updateLocationPermissions(locationPayload);

      // Update the data real time once updated
      if (this.userAction !== "create") {
        this.$emit("updatePermissions", {
          type: "locationPermissions",
          data: locationResult.locationPermissions,
        });
      }
    },
    async updateUserAdminPermissions() {
      /* Super admin permission - only Simpatra Admin can access */
      if (this.isSimpatraAdmin === true) {
        const supAdminPermissions = [];
        Object.keys(this.superAdminPermissions).forEach((permission) => {
          const valNum = this.superAdminPermissions[permission] ? 1 : 0;
          supAdminPermissions.push({
            name: permission,
            value: valNum,
          });
        });

        const superAdminPayload = {
          orgId: this.getOrgId,
          userId: this.user.id,
          supAdminPermissions,
        };

        const superAdminResult = await this.updateSuperAdminPermissions(superAdminPayload);

        if (this.userAction !== "create") {
          this.$emit("updatePermissions", {
            type: "superAdminPermissions",
            data: superAdminResult.superAdminPermissions,
          });
        }
      }
    },
    async updateUserOrgPermissions() {
      const directoryValue = this.isIncludedDirectory ? 2 : 0;
      const approverValue = this.isApprover ? 2 : 0;

      const orgAdminValue = this.isOrgAdmin ? 2 : 0;

      const orgPermissions = [
        {
          value: orgAdminValue,
          name: "OrgProfile",
        },
        {
          value: orgAdminValue,
          name: "OrgUsers",
        },
        {
          value: orgAdminValue,
          name: "OrgPayment",
        },
        {
          value: orgAdminValue,
          name: "OrgLocations",
        },
        {
          value: orgAdminValue,
          name: "OrgInvoices",
        },
        {
          value: directoryValue,
          name: "OrgDirectory",
        },
        {
          value: approverValue,
          name: "Approver",
        },
      ];

      const orgPayload = {
        orgId: this.getOrgId,
        userId: this.user.id,
        orgPermissions,
        type: this.userAction,
      };

      // IF orgAdminValue === 0 USE API TO REMOVE THE ORG ADMIN ROLE
      if (orgAdminValue === 0) {
        await this.removeOrgAdminPermission(orgPayload);
      } else {
        await this.grantOrgAdminPermission(orgPayload);
      }

      const resultOrgPermissions = await this.updateOrgPermissions(orgPayload);

      if (this.userAction === "create") {
        this.updateUserWithNew(resultOrgPermissions);
      } else {
        this.$emit("updatePermissions", {
          type: "organizationPermissions",
          data: resultOrgPermissions.organizationPermissions,
        });

        this.$emit("updatePermissions", {
          type: "admin",
          data: this.isOrgAdmin,
        });
      }
    },
    async handleFormSubmit() {
      this.loading = true;
      if (this.userAction === "create") {
        this.$emit("handleStep", 3);
      }

      await this.updateLocationRole();
      await this.updateUserAdminPermissions();
      await this.updateUserOrgPermissions();

      this.loading = false;
      this.$emit("toggleForm");
    },
  },
};
</script>

<style lang="scss" scoped>
.location-permissions {
  display: flex;
  flex-flow: wrap;
}
.permission {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 45%;
}
.directory {
  margin-right: 10px;
  margin-bottom: 10px;
  color: #ed8f8f !important;
  border-color: #ed8f8f !important;
}
.directory-txt {
  color: #67c23a !important;
}
::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-right: 10px;
  margin-left: 0px;
}
p {
  margin: 0;
}
.info-label {
  font-size: 12px;
  font-weight: 600;
  color: $dark-blue;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.permission-select {
  margin-right: 15px;
  margin-bottom: 15px;
}
.save-button {
  margin-top: 10px;
}
.secondary-admin {
  height: 25px;
  color: #ed8f8f;
  background-color: #fff;
  border: 1px solid #ed8f8f;
  &:hover {
    background-color: #ed8f8f;
    color: white;
  }
}
.secondary-admin.is-checked {
  background-color: #ed8f8f;
  color: white;
}
</style>
