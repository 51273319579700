<template>
  <div class="form-container">
    <BaseComponent :is-loading="isLoading">
      <template slot="content">
        <a-select
          mode="multiple"
          :default-value="selectedLocationIds"
          class="context-select"
          placeholder="Select Location"
          style="width: 100%"
          @change="handleChange"
        >
          <a-select-opt-group v-if="activeLocations.length > 0">
            <span slot="label">Active Locations</span>
            <a-select-option v-for="item in activeLocations" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select-opt-group>
          <a-select-opt-group v-if="deactiveLocations.length > 0">
            <span slot="label">Deactivated Locations</span>
            <a-select-option v-for="item in deactiveLocations" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select-opt-group>
        </a-select>
        <BaseButton type="form" :disabled="isLocationEmpty || isLoading" @click="handleFormSubmit">
          {{ submitText }}
        </BaseButton>
      </template>
    </BaseComponent>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isEmpty } from "lodash";
import BaseButton from "@/components/common/BaseButton.vue";
import BaseComponent from "@/components/shared-components/BaseComponent.vue";

export default {
  name: "FormUserLocations",
  components: {
    BaseButton,
    BaseComponent,
  },
  props: {
    user: {
      type: Object,
    },
    submitText: {
      type: String,
      required: true,
    },
    userAction: {
      type: String,
      default: "create",
      validator(value) {
        const options = ["create", "edit"];
        return options.indexOf(value) !== -1;
      },
    },
    selectedLocationIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedLocations: [],
      isLoading: true,
      defaultValues: [],
      activeLocations: [],
      deactiveLocations: [],
      // LOCATIONS FOR THIS USER
      allLocations: [],
    };
  },
  computed: {
    ...mapGetters(["getOrgId"]),
    ...mapGetters("locationsModule", ["getCurrentUserAvailableLocations"]),
    isLocationEmpty() {
      return isEmpty(this.defaultValues);
    },
  },
  mounted() {
    this.createLocationOptions();
    this.isLoading = false;
  },
  methods: {
    ...mapActions("accountsModule", ["updateLocation"]),
    async handleFormSubmit() {
      const payload = {
        orgId: this.getOrgId,
        userId: this.user.id,
        locationIdArray: this.defaultValues,
      };


      this.isLoading = true;
      const result = await this.updateLocation(payload);
      this.isLoading = false;
      if (this.userAction === "create") {
        this.$emit("handleStep", {
          step: 2,
          payload: result,
        });
      }
      if (this.userAction === "edit") {
        this.$emit("updateLocations", result);
        this.$emit("toggleForm");
      }
    },
    handleChange(value) {
      this.defaultValues = value;
    },
    createLocationOptions() {
      let availableLocationsForOrg = this.getCurrentUserAvailableLocations;
      availableLocationsForOrg.forEach((location) => {
        this.allLocations.push({
          value: location.id,
          label: location.name,
        });
        if (location.isActive === true) {
          this.activeLocations.push({
            value: location.id,
            label: location.name,
          });
        } else {
          this.deactiveLocations.push({
            value: location.id,
            label: location.name,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep {
//   .ant-select-selection__rendered > ul > li:first-child {
//     .ant-select-selection__choice__remove {
//       display: none;
//     }
//   }
// }
.location-select {
  width: 100%;
}
.separator {
  border-bottom: 2px solid #e6e6e6;
  margin: 0 20px;
  padding: 0;
}
</style>
