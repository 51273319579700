<template>
  <div
    class="layout-centered"
    :class="{
      'm-top-20': isModule,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "LayoutCentered",
  props: {
    isModule: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.layout-centered {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 90%;
}

@media screen and (max-width: 1400px) {
  //   .layout-left {
  //   max-width: 1020px !important;
  // }
}
</style>
